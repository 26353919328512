<template>
  <div class="app-container">
    <el-row class="margin-bottom-20">
      <el-col :lg="16" :xs="16" :offset="0">
        <el-input prefix-icon="el-icon-search" size="mini" placeholder="ID Paypal, Nombre Suscriptor, Correo Suscriptor" v-model="searchForm.query"
          v-on:change="handleSearchFilter()" clearable></el-input>
      </el-col>
      <el-col :lg="2" :xs="8" :offset="0">
        <el-button @click.stop.prevent="handleSearchFilter" class="margin-left-10" size="mini" type="primary"
          icon="el-icon-search">Buscar</el-button>
      </el-col>
      <el-col :lg="2" :xs="8" :offset="0">
        <el-button class="margin-left-10" @click.stop.prevent="handleClean" size="mini" type="warning"
          icon="el-icon-delete">Limpiar</el-button>
      </el-col>
      <el-col :lg="2" :xs="8" :offset="0">
        <el-button
          @click.stop.prevent="handleExport"
          type="success"
          icon="el-icon-bottom"
          size="mini"
        >Exportar</el-button>
      </el-col>
    </el-row>
    <el-row>
      <el-col class="grid-content" style="display: flex; justify-content: center;" :lg="4" :md="4" :sm="8" :xs="24">
        <el-select v-model="searchForm.status" v-on:change="handleSearchFilter()" size="large" placeholder="Estatus">
          <el-option
            v-for="option in statusOptions"
            :key="option.status"
            :label="option.status"
            :value="option.status">
          </el-option>
        </el-select>
      </el-col>
      <el-col style="display: flex; justify-content: center;" :lg="16" :md="16" :sm="14" :xs="24" justify="end">
        <div class="grid-content bg-purple">
          <span class=" margin-filter">Registros por fecha</span>
          <el-date-picker
            v-model="filterDateRegisterRange"
            v-on:change="handleFilterDateRegister()"
            size="mini"
            type="daterange"
            align="right"
            unlink-panels
            format="dd-MM-yyyy"
            range-separator="a"
            start-placeholder="Fecha inicial"
            end-placeholder="Fecha final"
            :picker-options="pickerOptions">
          </el-date-picker>
        </div>
      </el-col>
    </el-row>
    <!---- Paginacion -->
    <el-row type="flex" justify="end">
      <el-pagination @size-change="handleChangeItemPage"  :page-sizes="[25, 50, 100, 200]"  :page-size="25" layout="total,sizes,prev, pager, next" @current-change="handleCurrentChange"
        :total="parseInt(totalPages)">
      </el-pagination>
    </el-row>
    <!---- Tabla de Donaciones ----->
    <el-table size="mini" v-loading="loading" :data="list" element-loading-text="Loading" height="600" fit border
      highlight-current-row style="width: 100%">
      <el-table-column label="ID Paypal" fixed width="120" align="center">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" placement="top">
              <div slot="content">{{ isNotEmpty(scope.row.paymentId) }}</div>
              <span>{{ scope.row.paymentId !== undefined ? scope.row.paymentId.toString().substr(-6) : 'N/D' }}</span>
            </el-tooltip>
          </template>
        </el-table-column>
      <el-table-column label="ID" width="120" align="center">
        <template slot-scope="scope">
          <el-tooltip class="item" effect="dark" placement="top">
            <div slot="content">{{ isNotEmpty(scope.row._id) }}</div>
            <span>{{ scope.row._id !== undefined ? scope.row._id.toString().substr(-6) : 'N/D' }}</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="Nombre Suscriptor" align="center" width="220">
        <template slot-scope="scope">
          <span>{{ isNotEmpty(scope.row.userName) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Correo Suscriptor" align="center" width="220">
        <template slot-scope="scope">
          <span>{{ isNotEmpty(scope.row.userEmail) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Nombre Proyecto" align="center" min-width="160">
        <template slot-scope="scope">
          <span>{{ isNotEmpty(scope.row.projectName) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Estatus Donativo" align="center" width="140">
        <template slot-scope="scope">
          <el-tag  v-if="scope.row.status.toLowerCase() == 'completed' || scope.row.status.toLowerCase() == 'active'"  size="small" type="success">{{ validateStatus(scope.row.status) }}</el-tag>
          <el-tag  v-else size="small" type="primary">{{ validateStatus(scope.row.status.toLowerCase()) }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="Monto Donativo" align="center" width="140">
        <template slot-scope="scope">
          <span>{{ '$'+scope.row.amount+' '+scope.row.currency }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Monto Donativo Recibido" align="center" width="120">
        <template slot-scope="scope">
          <span>{{ '$'+scope.row.amountReceived+' '+scope.row.currency }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Mostrar Eventos" align="center" width="120">
        <template slot-scope="scope">
          <el-button type="primary" @click="handleGoAndSearchEvents( scope.row.paymentId )" size="mini" >Ver</el-button>
        </template>
      </el-table-column>
      <el-table-column label="Fecha de Registro" width="180" align="center" fixed="right">
        <template slot-scope="scope">
          <span>{{ scope.row.created_at | formatDateTime }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Fecha de Actualización" width="180" align="center" fixed="right">
        <template slot-scope="scope">
          <span>{{ scope.row.updated_at | formatDateTime }}</span>
        </template>
      </el-table-column>
      <!--<el-table-column label="Descripcion de Donativo" type="expand" width="110" align="center">
        <template slot-scope="scope">
          <el-table :data="scope.row.metadata" size="mini" border highlight-current-row>
            <el-table-column label="Por definirse" align="center">
              <template slot-scope="meta">
                <span>{{ meta.row.data !== undefined ? meta.row.data : 'N/D' }}</span>
              </template>
            </el-table-column>
          </el-table>
        </template>
      </el-table-column>-->
    </el-table>
  </div>
</template>

<script>
import { searchPayment, getPagePayment, exportExcelPayments, filterDataPayments } from '@/api/paypal.js'
const confElement = document.getElementById('config')
const conf = JSON.parse(confElement.innerHTML)
const urlServer = conf.baseApi
export default {
  name: 'contributions-paypal',
  data () {
    return {
      pathServer: urlServer,
      searchForm: {
        query: '',
        userId: null,
        status: null,
        initDate: '',
        endDate: ''
      },
      filterDateRegisterRange: '',
      totalPages: '',
      currentPage: '',
      loading: false,
      sizePerPage: 25,
      list: [],
      statusOptions: [],
      pickerOptions: {
        shortcuts: [{
          text: 'Semana pasada',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: 'Mes pasado',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '3 Meses atrás',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '6 Meses atrás',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 180)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '9 Meses atrás',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 270)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '1 Año atrás',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 365)
            picker.$emit('pick', [start, end])
          }
        }]
      }
    }
  },
  mounted () {
    var idTemp = this.$route.params._id
    if (idTemp) {
      this.searchForm.userId = idTemp
    }
    var idTemp2 = this.$route.params.subscriptionId
    if (idTemp2) {
      this.searchForm.subscriptionId = idTemp2
    }
    this.fetchFilterStatus()
    this.handleSearchFilter()
  },
  methods: {
    isNotEmpty (contain) {
      return contain !== undefined || contain !== '' ? contain : 'N/D'
    },
    validateStatus (contain) {
      switch (contain) {
        case 'active':
          return 'Activo'
        case 'completed':
          return 'Completado'
        case 'requires_payment_method':
          return 'Pendiente'
        case 'canceled':
          return 'Cancelado'
        default:
          return contain
      }
    },
    handleGoAndSearchEvents (id) {
      this.$router.push({ name: 'paypal.events', params: { paymentId: id } })
    },
    // --- data search ----
    async handleSearchFilter () {
      this.loading = true
      await searchPayment(this.searchForm)
        .then((response) => {
          this.list = response.data.data
          this.totalPages = response.data.total
          this.list.forEach(item => (item.metadata = [item.metadata]))
          console.log(this.list)
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async handleChangeItemPage (val) {
      this.loading = true
      this.sizePerPage = val
      await getPagePayment(this.currentPage, val, this.searchForm)
        .then(response => {
          this.list = response.data.data
          this.totalPages = response.data.total
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async handleCurrentChange (page) {
      this.currentPage = page
      this.loading = true
      await getPagePayment(page, this.sizePerPage, this.searchForm)
        .then(response => {
          this.list = response.data.data
          this.totalPages = response.data.total
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async handleExport () {
      this.loading = true
      await exportExcelPayments(
        this.searchForm
      ).then(response => {
        const url = window.URL.createObjectURL(new Blob([response]))
        const link = document.createElement('a')
        link.href = url
        const name = 'Reporte-de-Donaciones-Paypal-' + new Date() + '.xlsx'
        link.setAttribute('download', name)
        document.body.appendChild(link)
        link.click()
        console.log('Archivo Exportado')
      })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    handleClean () {
      this.filterDateRegisterRange = []
      this.searchForm = {
        query: '',
        userId: null,
        status: null,
        initDate: '',
        endDate: ''
      }
      this.handleSearchFilter()
    },
    handleFilterDateRegister () {
      if (this.filterDateRegisterRange) {
        this.searchForm.initDate = this.filterDateRegisterRange[0]
        this.searchForm.endDate = this.filterDateRegisterRange[1]
        this.handleSearchFilter()
      } else {
        this.searchForm.initDate = ''
        this.searchForm.endDate = ''
        this.handleSearchFilter()
      }
    },
    async fetchFilterStatus () {
      const formData = new FormData()
      formData.append('fieldSearch', 'status')
      filterDataPayments(formData)
        .then((response) => {
          this.statusOptions = response.data
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    }
  }
}
</script>

<style lang="css">

</style>
